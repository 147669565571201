<template>
    <div class="card flex-1 md:w-4/6 xl:w-3/6">
        <header class="border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ $t('settings.invoice_logo') }}</h1>
        </header>

        <div class="flex items-center flex-col px-8 h-48">
            <img v-if="invoiceLogoSrc" :src="invoiceLogoSrc" class="max-h-full">
            <el-upload
                v-else
                ref="upload"
                action="#"
                :class="{ 'hide-new-file': file }"
                list-type="picture-card"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :multiple="false"
                accept=".jpg,.png,.jpeg"
                :limit="1"
                :auto-upload="false"
            >
                <i slot="default" class="el-icon-camera" />
            </el-upload>
        </div>
        <div class="flex justify-end mt-8 px-8">
            <el-button type="danger" :loading="$wait.is('deleting')" :disabled="!userCan('update settings') || !invoiceLogoSrc" @click="removeLogo">
                {{ $t('common.delete') }}
            </el-button>
            <el-button
                type="primary"
                :loading="$wait.is('uploading')"
                :disabled="!file || invoiceLogoSrc || !userCan('update settings')"
                @click="updateLogo"
            >
                {{ $t('common.update') }}
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            invoiceLogoSrc: '',
            file:           null,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'invoice_logo');
    },

    created() {
        this.getSettings();
    },

    methods: {
        async getSettings() {
            try {
                this.$wait.start('loading');
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/settings/economy`);
                this.invoiceLogoSrc = data.invoice_logo_src;
            } finally {
                this.$wait.end('loading');
            }
        },

        async updateLogo() {
            try {
                this.$wait.start('uploading');

                const formData = new FormData();
                formData.append('file', this.file.raw);

                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/settings/economy/upload_logo`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                this.$notify.success({ title: this.$t('common.success') });
                this.getSettings();
            } finally {
                this.$wait.end('uploading');
            }
        },

        handleRemove() {
            this.file = null;
        },

        handleChange(file) {
            this.file = file;
        },

        async removeLogo() {
            this.$wait.start('deleting');
            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/settings/economy/delete_logo`);
            this.getSettings();
            this.$wait.end('deleting');
        },
    },
};
</script>
<style>
    .hide-new-file .el-upload--picture-card {
        display: none !important;
    }
</style>
